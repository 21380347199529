import React, { useContext, useState } from 'react';
import { Link, useStaticQuery, graphql, navigate } from 'gatsby';
import { CSSTransition } from 'react-transition-group';

// contexts
import { HeaderContext } from '@src/contexts/Header';

// components
import CartButton from '@src/components/buttons/CartButton';

// svgs
import Arrow from '@src/svgs/arrow.svg';
import Close from '@src/svgs/close.svg';

// styles
import './header.scss';

export default function Header(props) {
    // eslint-disable-next-line no-unused-vars
    const [state] = useContext(HeaderContext);
    const { banner, toggleMenu, menuOn } = props;

    let search;
    if (typeof window !== 'undefined') {
        search = window.location.search;
    } else {
        search = {};
    }

    const query = new URLSearchParams(search).get('s');
    const [searchQuery, setSearchQuery] = useState(query || '');

    const [aboutActive, setAboutActive] = useState(false);
    const [eduActive, setEduActive] = useState(false);
    const [shopActive, setShopActive] = useState(false);

    const data = useStaticQuery(graphql`
        {
            globals: craftGlobalSetInterface(handle: { eq: "menuContent" }) {
                ... on Craft_menuContent_GlobalSet {
                    handle
                    entryList {
                        remoteId
                        id
                        title
                        uri
                    }
                }
            }
            aboutEntries: allCraftAboutUsDefaultEntry(
                filter: { enabled: { eq: true } }
                sort: { order: ASC, fields: lft }
            ) {
                nodes {
                    remoteId
                    id
                    sectionHandle
                    title
                    level
                    uri
                }
            }
            educationEntries: allCraftEducationDefaultEntry(
                filter: { enabled: { eq: true } }
                sort: { order: ASC, fields: lft }
            ) {
                nodes {
                    remoteId
                    id
                    sectionHandle
                    title
                    level
                    uri
                }
            }
            shopEntries: allCraftShopDefaultEntry(
                filter: { enabled: { eq: true } }
                sort: { order: ASC, fields: lft }
            ) {
                nodes {
                    remoteId
                    id
                    sectionHandle
                    title
                    level
                    uri
                }
            }
        }
    `);

    const aboutEntries = data.aboutEntries.nodes;
    const educationEntries = data.educationEntries.nodes;
    const shopEntries = data.shopEntries.nodes;
    const additional = data.globals;

    const burger = (
        <button
            className="header__cta scale-in"
            aria-label="Toggle menu"
            type="button"
            style={{ width: 24, height: 24, cursor: 'pointer' }}
            onClick={toggleMenu}>
            <Arrow />
        </button>
    );
    const close = (
        <div className="scale-in relative top-[2px]">
            <button
                type="button"
                aria-label="Toggle menu"
                onClick={toggleMenu}
                style={{ width: 24, height: 24, cursor: 'pointer' }}>
                <Close />
            </button>
        </div>
    );

    const closeMobileMenu = () => {
        if (window.innerWidth < 1024) toggleMenu();
    };

    const toggleList = name => {
        if (name === 'about' && !aboutActive) {
            setAboutActive(true);
            setEduActive(false);
            setShopActive(false);
        } else if (name === 'about' && aboutActive) {
            setAboutActive(false);
        }

        if (name === 'edu' && !eduActive) {
            setEduActive(true);
            setAboutActive(false);
            setShopActive(false);
        } else if (name === 'edu' && eduActive) {
            setEduActive(false);
        }

        if (name === 'shop' && !shopActive) {
            setShopActive(true);
            setAboutActive(false);
            setEduActive(false);
        } else if (name === 'shop' && shopActive) {
            setShopActive(false);
        }

        if (name === '') {
            setShopActive(false);
            setAboutActive(false);
            setEduActive(false);
            closeMobileMenu();
        }
    };

    const submitSearch = e => {
        e.preventDefault();
        navigate(`/search?s=${searchQuery}`);
    };

    return (
        <header className={`header z-header ${banner ? 'header--padded' : null}`}>
            <div className="header__header flex items-center justify-between">
                <button
                    className="h2"
                    aria-label="Toggle menu"
                    type="button"
                    style={{ cursor: 'pointer' }}
                    onClick={toggleMenu}>
                    <h5 className="h5" style={{ margin: 0 }}>
                        Menu
                    </h5>
                </button>
                {menuOn ? close : burger}
            </div>
            <CSSTransition classNames="menu-transition" in={menuOn} appear timeout={600}>
                <nav className="header__nav">
                    <ul>
                        <li className="header__list-item">
                            <button
                                type="button"
                                aria-label="Toggle about"
                                onClick={() => toggleList('about')}>
                                About Us
                            </button>
                        </li>
                        <ul className={`${aboutActive ? 'active' : ''} header__nested-list`}>
                            {aboutEntries.map(entry => (
                                <li key={entry.id} className="header__list-item">
                                    <Link
                                        to={`/${entry.uri}`}
                                        activeStyle={{ fontWeight: 600 }}
                                        onClick={closeMobileMenu}>
                                        {entry.title}
                                    </Link>
                                </li>
                            ))}
                        </ul>

                        <li className="header__list-item">
                            <Link
                                to="/poetry-festival"
                                activeStyle={{ fontWeight: 600 }}
                                onClick={() => toggleList('')}>
                                Poetry Festival
                            </Link>
                        </li>

                        <li className="header__list-item">
                            <Link
                                to="/projects-and-events/all"
                                activeStyle={{ fontWeight: 600 }}
                                onClick={() => toggleList('')}>
                                Project Archive
                            </Link>
                        </li>

                        <li className="header__list-item">
                            <button
                                type="button"
                                aria-label="Toggle about"
                                onClick={() => toggleList('edu')}>
                                Education
                            </button>
                        </li>
                        <ul className={`${eduActive ? 'active' : ''} header__nested-list`}>
                            {educationEntries.map(entry => (
                                <li key={entry.id} className="header__list-item">
                                    <Link
                                        to={`/${entry.uri}`}
                                        activeStyle={{ fontWeight: 600 }}
                                        onClick={closeMobileMenu}>
                                        {entry.title}
                                    </Link>
                                </li>
                            ))}
                        </ul>

                        <li className="header__list-item">
                            <button
                                type="button"
                                aria-label="Toggle about"
                                onClick={() => toggleList('shop')}>
                                Shop
                            </button>
                        </li>
                        <ul className={`${shopActive ? 'active' : ''} header__nested-list`}>
                            {shopEntries.map(entry => (
                                <li key={entry.id} className="header__list-item">
                                    <Link
                                        to={`/${entry.uri}`}
                                        activeStyle={{ fontWeight: 600 }}
                                        onClick={closeMobileMenu}>
                                        {entry.title}
                                    </Link>
                                </li>
                            ))}
                        </ul>

                        {additional ? (
                            <ul style={{ marginTop: 24 }}>
                                {additional.entryList.map(entry => (
                                    <li
                                        key={entry.id}
                                        className="header__list-item"
                                        style={{ fontWeight: 600 }}>
                                        <Link
                                            to={`/${entry.uri}`}
                                            activeStyle={{ fontWeight: 600 }}
                                            onClick={() => toggleList('')}>
                                            {entry.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        ) : null}
                    </ul>

                    <div className="relative mt-[24px] pb-6">
                        <form action="/" method="get" onSubmit={e => submitSearch(e)}>
                            <input
                                type="text"
                                name="s"
                                minLength="3"
                                onInput={e => setSearchQuery(e.target.value)}
                                className="input"
                                value={searchQuery}
                                placeholder="Search"
                            />
                            <button type="submit" name="submit" className="input-button">
                                <span className="sr-only">submit</span>
                            </button>
                        </form>
                    </div>

                    <div className="header__list-item header__cart-item fade-in">
                        <CartButton copy="Cart" classes="header__cart" />
                    </div>
                </nav>
            </CSSTransition>
        </header>
    );
}
